<template>
  <div>
    <!-- Display the address if the user has not changed it -->
    <div
      @dblclick="changeAdress"
      v-if="changeDelivery"
      :class="{ 'lg:py-4': name }"
      class="flex items-center px-4 py-4 space-x-2 bg-white rounded shadow-md flex-nowrap lg:py-2"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>
      </div>
      <p class="text-sm">{{ Address }}</p>
      <div class="flex justify-end flex-grow" @click="changeAdress">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>

    <!-- Input field to change address -->
    <div v-else class="flex space-x-2 bg-white rounded shadow-md">
      <div
        :class="{ 'lg:py-4': name }"
        class="z-30 flex w-4/5 px-4 py-4 lg:py-2"
      >
        <vue-google-autocomplete
          ref="address"
          id="map"
          country="ng"
          @placechanged="getAddressData"
          v-model="inputAddress"
          :types='["establishment"]'
          @keyup.enter="FilterAddress"
          class="w-full focus:outline-none"
          placeholder="Enter Delivery Address"
        />
      </div>

      <!-- Cancel button to revert changes -->
      <div
        v-if="cancel"
        @click="Unchange"
        class="flex items-center justify-end flex-grow font-bold text-gray-400"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div>

      <!-- Button to trigger address change -->
      <div
        @click="FilterAddress"
        class="flex justify-end md:justify-center font-semibold rounded-r flex-grow z-30 bg-black text-brand text-sm py-0.5 px-3"
      >
        <button class="flex items-center justify-end">Change</button>
      </div>
    </div>
    
    <!-- Modal for clearing the cart -->
    <div class="text-black" v-if="showPopup">
      <Modal :show="true">
        <h1 class="text-xl font-semibold">Do you want to proceed?</h1>
        <p class="mt-4 text-sm">
          You have tried to add items from different time belts or different
          restaurants. Changing any of these will clear your current cart and a
          new one will be created.
        </p>
        <div class="flex justify-end space-x-4">
          <button
            @click="closeModal"
            class="px-3 py-2 text-sm bg-gray-200 rounded-sm"
          >
            No
          </button>
          <button
            @click="clearCart(itemTopush, timebeltTopush)"
            class="px-3 py-2 text-sm rounded-sm bg-brand"
          >
            Yes
          </button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Modal from "@/components/Modal.vue";
export default {
  name: "DeliveryAddress",
  props: ["name", "newChangedAddress"],
  components: {
    VueGoogleAutocomplete,
    Modal,
  },
  data() {
    return {
      Address: "",
      inputAddress: "",
      changeDelivery: true,
      cancel: false,
      showPopup: false,
      newAddress: {},
    };
  },
  mounted() {
    this.Address = this.$store.getters.getAddress?.address || "";
  },
  methods: {
    changeAdress() {
      this.changeDelivery = false;
    },
    FilterAddress() {
      if (this.inputAddress && this.inputAddress === this.newAddress.address) {
        if (this.$route.name === "ResturantsPage") {
          this.showPopup = true;
        } else {
          this.saveAddress();
        }
      } else {
        this.$store.dispatch("errAddress", "Please enter a valid address.");
      }
    },
    saveAddress() {
      const geoData = {
        address: this.newAddress.address,
        latitude: this.newAddress.latitude,
        longitude: this.newAddress.longitude,
      };
      this.$store.dispatch("addDeliveryaddress", JSON.stringify(geoData));
      this.Address = this.inputAddress;
      this.changeDelivery = true;
      this.$emit("getNewRestaurants");
    },
    getAddressData(addressData, placeResultData) {
      this.newAddress = {
        ...addressData,
        address: addressData.street_number
          ? `${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`
          : `${addressData.route}, ${addressData.locality}, ${addressData.country}`,
        placeId: placeResultData.place_id,
        establishmentName: placeResultData.name,
      };
      this.inputAddress = this.newAddress.address;
    },
    closeModal() {
      this.showPopup = false;
    },
    clearCart() {
      this.$store.dispatch("emptyCart");
      this.$store.dispatch("emptyResurantName");
      this.saveAddress();
      this.$router.push({ name: "Resturants" });
    },
    Unchange() {
      this.changeDelivery = true;
    },
  },
};
</script>

<style scoped>
/* Add any custom styles */
</style>